import React, { useState, useEffect } from "react";
import { getLatestProjects } from "../services/apiServices"; // Make sure to import the API service
import ButtonLoader from "./loaders/ButtonLoader"; // Assuming the loader is in this path

const PortfolioSection = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch projects from the API on component mount
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getLatestProjects();
        if (response.status === "success") {
          setProjects(response.data || []); // Populate the projects array with the fetched data
        }
      } catch (error) {
        console.error("Failed to fetch projects:", error);
      } finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };

    fetchProjects();
  }, []);

  return (
    <section id="portfolio" className="py-20 ">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-5xl font-extrabold text-gray-900">
          Explore My Latest Work
        </h2>
        <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
          I empower clients globally to embrace modern technology and elevate
          customer experiences.
        </p>

        {/* Loader while fetching projects */}
        {loading ? (
          <div className="flex justify-center items-center mt-10">
            <ButtonLoader /> {/* Display the loader */}
          </div>
        ) : (
          <>
            {/* Portfolio Items */}
            <div
              className={` mt-12 grid ${
                projects.length === 1
                  ? "flex justify-center m-auto"
                  : "grid-cols-1 md:grid-cols-2"
              } gap-16`}
            >
              {projects.map((project, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center space-y-6 md:space-y-0 md:space-x-8 gap-8 max-w-96"
                >
                  <img
                    src={project.projectImage}
                    alt={project.projectName}
                    className="w-full rounded-lg shadow-lg object-cover"
                  />
                  <div className="text-left">
                    <h3 className="text-2xl font-bold text-gray-800 leading-tight">
                      {project.projectName}
                    </h3>
                    <p className="mt-2 text-gray-600">
                      {project.projectDescription}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default PortfolioSection;
