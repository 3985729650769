import React from "react";

import react from "../assets/techStacks/react.png";
import node from "../assets/techStacks/node.png";
import tailwind from "../assets/techStacks/tailwind.png";

const techStack = [
  { name: "REACT", icon: react },
  { name: "NODE.JS", icon: node },
  { name: "TAILWIND", icon: tailwind },
];

function TechStack() {
  return (
    <section className="py-12 text-center">
      <h2 className="text-3xl font-bold dark:text-white">My Tech Stack</h2>
      <div className="mt-8 flex justify-center space-x-6">
        {techStack.map((tech, index) => (
          <div key={index} className="text-center">
            <img
              src={tech.icon}
              alt={tech.name}
              className="w-16 h-16 mx-auto"
            />
            <p className="mt-2 dark:text-gray-300">{tech.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TechStack;
