import axios from "axios";

// Set up your base URL for the API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual base URL

// Function to create an Axios instance with a bearer token
const createApiClient = (store) => {
  const apiClient = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return apiClient;
};

// get introduction video
export const getIntroductionVideo = async () => {
  try {
    // example response
    // {
    //     "status": "success",
    //     "data": {
    //       "_id": "670036b02d804ab9c9fc9ee4",
    //       "introductionVideo": "https://mantiqbucket.s3.amazonaws.com/file_1728068012023_SampleVideo_1280x720_5mb.mp4",
    //       "createdAt": "2024-10-04T18:40:48.465Z",
    //       "updatedAt": "2024-10-04T18:53:49.299Z",
    //       "__v": 0
    //     },
    //     "message": "Introduction Video fetch Successfully"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get("/introductionVideo");
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// get latest projects
export const getLatestProjects = async () => {
  try {
    // example response
    // {
    //     "status": "success",
    //     "data": [
    //       {
    //         "_id": "670280b4c5c3e4f646d74bb1",
    //         "projectImage": "https://mantiqbucket.s3.amazonaws.com/file_1728217256022_profile.jpg",
    //         "projectName": "project 2",
    //         "projectDescription": "ok cgvhdjks vjdk",
    //         "sort": 1,
    //         "createdAt": "2024-10-06T12:21:08.290Z",
    //         "updatedAt": "2024-10-06T17:14:18.213Z",
    //         "__v": 0
    //       },
    //       {
    //         "_id": "6701412fc56b88c4daff9f9f",
    //         "projectImage": "https://mantiqbucket.s3.amazonaws.com/file_1728217110233_Capture.PNG",
    //         "projectName": "hi ok hi",
    //         "projectDescription": "hi ok description",
    //         "sort": 2,
    //         "createdAt": "2024-10-05T13:37:51.525Z",
    //         "updatedAt": "2024-10-06T12:32:23.447Z",
    //         "__v": 0
    //       }
    //     ],
    //     "message": "Projects fetched successfully"
    //   }
    const apiClient = createApiClient();
    const response = await apiClient.get("/projects");
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// get reviews
export const getReviews = async () => {
  try {
    // example response
    // {
    //   "status": "success",
    //   "data": [
    //     {
    //       "_id": "670289c3b4d12a4309a18339",
    //       "personName": "John Doe",
    //       "influence": "CErtyui",
    //       "review": "This company is amazing!",
    //       "sort": 1,
    //       "createdAt": "2024-10-06T12:59:47.325Z",
    //       "updatedAt": "2024-10-06T17:14:12.605Z",
    //       "__v": 0
    //     },
    //     {
    //       "_id": "6702899ab4d12a4309a18323",
    //       "personName": "John Doe",
    //       "influence": "CE",
    //       "review": "This company is amazing!",
    //       "sort": 2,
    //       "createdAt": "2024-10-06T12:59:06.688Z",
    //       "updatedAt": "2024-10-06T16:34:31.191Z",
    //       "__v": 0
    //     },
    //     {
    //       "_id": "6702c5778e32da2a9c17c95c",
    //       "personName": "hj",
    //       "influence": "tfyuji",
    //       "review": "jhjk",
    //       "sort": 3,
    //       "createdAt": "2024-10-06T17:14:31.007Z",
    //       "updatedAt": "2024-10-06T17:14:31.007Z",
    //       "__v": 0
    //     }
    //   ],
    //   "message": "Reviews fetched successfully"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get("/reviews");
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// create consultation
export const createConsultation = async (payload) => {
  try {
    // example payload
    // {
    //   "firstName": "John",
    //   "lastName": "Doe",
    //   "email": "johndoe@example.com",
    //   "phoneNumber": "+1-234-567-8901",
    //   "companyName": "Tech Innovations Ltd.",
    //   "region": "North America",
    //   "projectDetail": "We are looking for a scalable solution to optimize our product development workflow and increase team productivity. Our main focus is to improve automation in our DevOps pipeline and enhance the user experience across our SaaS platform."
    // }

    const apiClient = createApiClient();
    const response = await apiClient.post("/consultation", payload);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
